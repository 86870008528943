import React from "react"
import { graphql, Link } from "gatsby"
import Layout from "../components/shared/layoutParallax"
import SEO from "../components/SEO/SEO"
import styled from "@emotion/styled"
import {
  wrapper,
  wrapper1200,
  featuredWrapper,
  noFeaturedWrapper,
  color,
  breakpoints,
  ContentStyles,
  ContentStylesExt,
} from "../utils/style"
import ButtonLink from "../components/shared/ButtonLink"
import Img from "gatsby-image"
import Breadcrumbs from "../components/shared/Breadcrumbs"

const WrapperDesktop = styled("div")`
  ${wrapper1200}
`

const FeaturedWrapper = styled("div")`
  ${featuredWrapper}
  position: relative;
  overflow: hidden;
  border-radius: 24px;
  .gatsby-image-wrapper {
    border-radius: 24px;
    max-height: 228px;
    &::after {
      content: "";
      width: 100%;
      height: 100%;
      background: ${color.transparentBlack};
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      margin: 0 auto;
      border-radius: 24px;
    }
  }
  h1 {
    font-weight: 800;
    font-size: 64px;
    line-height: 124.6%;
    text-shadow: ${color.goldTextShadow};
    position: absolute;
    top: 0;
    left: 32px;
    right: 32px;
  }
  @media (max-width: ${breakpoints.md}) {
    h1 {
      font-size: 26px;
      line-height: 32px;
    }
  }
  @media (max-width: ${breakpoints.sm}) {
    .gatsby-image-wrapper {
      max-height: 100%;
    }
    h1 {
      margin: 0;
      top: 20%;
    }
  }
`

const NoFeaturedWrapper = styled("div")`
  ${noFeaturedWrapper}
`

const ContentWrapper = styled("div")`
  ${wrapper}
  ${ContentStyles}
  ${ContentStylesExt}
  padding: 0;
  .prev-next {
    min-height: 70px;
    padding-bottom: 20px;
  }
  .next-button {
    float: right;
  }
`

const Bg = styled("div")`
  border-top: 1px solid ${color.transparentWhite1};
  padding: 15px 0 5px;
`
const RelatedWrapper = styled("div")`
  padding: 30px 0;
  margin-top: 10px;
  text-align: center;
  .h1 {
    text-align: left;
    margin-top: 0;
    span {
      font-size: 40px;
      line-height: 42px;
      text-transform: uppercase;
    }
  }
  ul {
    list-style: none;
    display: flex;
    flex-flow: row wrap;
    justify-content: space-between;
  }
  li {
    width: 48%;
    text-align: left;
    background: ${color.transparentWhite1};
    border-radius: 24px;
    padding: 24px 16px;
  }
  .gatsby-image-wrapper {
    border-radius: 24px;
  }
  .recent-name {
    font-weight: 600;
    font-size: 18px;
    line-height: 24px;
    color: ${color.gold};
    margin: 16px 0;
  }

  .recent-excerpt {
    font-size: 14px;
    line-height: 20px;
    max-height: 140px;
    overflow-y: hidden;
  }

  .button {
    width: 100%;
  }

  @media (max-width: ${breakpoints.md}) {
    ul {
      margin: 10px 0;
    }
    li {
      width: 48%;
    }
  }
  @media (max-width: ${breakpoints.sm}) {
    .h1 {
      span {
        font-size: 26px;
        line-height: 32px;
      }
    }
  }
  @media (max-width: ${breakpoints.xs}) {
    li {
      width: 100%;
      margin-bottom: 16px;
    }
  }
`

const BottomColumn = styled("div")`
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  .col {
    flex-basis: 48%;
    background: ${color.transparentWhite1};
    border-radius: 24px;
    padding: 16px;
    margin-bottom: 32px;
    margin-right: 1%;
  }
  @media (max-width: ${breakpoints.sm}) {
    .col {
      flex-basis: 100%;
      margin-right: 0;
      margin-bottom: 24px;
    }
  }
`

const BottomBanner = styled("div")`
  background: linear-gradient(
    128.46deg,
    rgba(169, 0, 0, 0.4) 25.23%,
    rgba(120, 0, 0, 0.1) 88.32%
  );
  padding: 32px 0;
  text-align: center;
  span {
    font-weight: 700;
    font-size: 22px;
    line-height: 32px;
    color: ${color.gold};
  }
`

const PostTemplate = ({ data, pageContext }) => {
  const post = data.wordpressPost
  const metaTitle =
    post.yoast_meta && post.yoast_meta.yoast_wpseo_title
      ? post.yoast_meta.yoast_wpseo_title
      : post.title + +"- " + process.env.OWNER
  const metaDesc =
    post.yoast_meta && post.yoast_meta.yoast_wpseo_metadesc
      ? post.yoast_meta.yoast_wpseo_metadesc
      : ""
  const relatedPosts = data.allWordpressPost
  const placeholder = data.placeholder
  const relatedIds = pageContext.related
  const breadcrumbs = pageContext.breadcrumbs
  const page_title = pageContext.page_title
  const prevPost = pageContext.prev
  const nextPost = pageContext.next
  const bottomBanner = post.acf.bottom_banner
  const content_field_one = post.acf.content_field_01
  const content_field_two = post.acf.content_field_02
  const content_field_tree = post.acf.content_field_03
  const content_field_four = post.acf.content_field_04
  const socialImage = post.featured_media?.localFile?.publicURL

  return (
    <Layout>
      <SEO
        title={metaTitle}
        description={metaDesc}
        pathname={post.path}
        key="seo"
        breadcrumbs={breadcrumbs}
        socialImage={socialImage}
      />
      <WrapperDesktop>
        <Breadcrumbs breadcrumbs={breadcrumbs} page_title={page_title} />
        {post.featured_media &&
        post.featured_media.localFile &&
        post.featured_media.localFile.childImageSharp &&
        post.featured_media.localFile.childImageSharp.fluid ? (
          <FeaturedWrapper key="featured-wrapper">
            <Img
              fluid={post.featured_media.localFile.childImageSharp.fluid}
              alt={post.featured_media.alt_text}
            />
            <h1 dangerouslySetInnerHTML={{ __html: post.title }} />
          </FeaturedWrapper>
        ) : (
          <NoFeaturedWrapper key="no-featured-wrapper">
            <h1 dangerouslySetInnerHTML={{ __html: post.title }} />
          </NoFeaturedWrapper>
        )}

        <ContentWrapper key="content-wrapper">
          <div
            key="content"
            dangerouslySetInnerHTML={{ __html: post.content }}
          />
          <BottomColumn>
            {content_field_one && (
              <div className="col">
                <span dangerouslySetInnerHTML={{ __html: content_field_one }} />
              </div>
            )}
            {content_field_two && (
              <div className="col">
                <span dangerouslySetInnerHTML={{ __html: content_field_two }} />
              </div>
            )}
            {content_field_tree && (
              <div className="col">
                <span
                  dangerouslySetInnerHTML={{ __html: content_field_tree }}
                />
              </div>
            )}
            {content_field_four && (
              <div className="col">
                <span
                  dangerouslySetInnerHTML={{ __html: content_field_four }}
                />
              </div>
            )}
          </BottomColumn>
          {(prevPost.slug || nextPost.slug) && (
            <div className="prev-next">
              {prevPost.slug &&
                !prevPost.slug.includes("provider-carousel") && (
                  <ButtonLink
                    className="button gold-button prev-button"
                    iconName="arrow-left"
                    iconLeft={true}
                    iconColor={color.white}
                    to={prevPost.slug + "/"}
                    text="ก่อนหน้า"
                  />
                )}
              {nextPost.slug &&
                !nextPost.slug.includes("provider-carousel") && (
                  <ButtonLink
                    className="button gold-button next-button"
                    to={nextPost.slug + "/"}
                    iconColor={color.white}
                    text="ต่อไป"
                  />
                )}
            </div>
          )}
        </ContentWrapper>

        {relatedIds.length > 0 && (
          <Bg key="related-bg">
            <RelatedWrapper>
              <h3 className="h1 rich-design">
                <span>Other Suggested Articles</span>
              </h3>
              <ul>
                {relatedPosts.edges.map((item, index) => (
                  <li key={item.node.slug + index}>
                    <Link to={"/" + item.node.slug + "/"}>
                      {item.node.featured_media &&
                      item.node.featured_media.localFile &&
                      item.node.featured_media.localFile.childImageSharp ? (
                        <div className="img-wrap">
                          <Img
                            fluid={
                              item.node.featured_media.localFile.childImageSharp
                                .fluid
                            }
                            alt={
                              item.node.featured_media.alt_text
                                ? item.node.featured_media.alt_text
                                : item.node.title
                            }
                          />
                        </div>
                      ) : (
                        <div className="img-wrap">
                          <Img
                            fluid={placeholder.childImageSharp.fluid}
                            alt={item.node.label}
                          />
                        </div>
                      )}
                      <div
                        className="recent-name"
                        dangerouslySetInnerHTML={{ __html: item.node.title }}
                      />
                      {/*<div className='recent-excerpt' dangerouslySetInnerHTML={{ __html: item.node.excerpt }}/>*/}
                      <button className="button transparent-button">
                        Read Article
                      </button>
                    </Link>
                  </li>
                ))}
              </ul>
            </RelatedWrapper>
          </Bg>
        )}
      </WrapperDesktop>
      {bottomBanner && (
        <BottomBanner>
          <WrapperDesktop>
            <span dangerouslySetInnerHTML={{ __html: bottomBanner }} />
          </WrapperDesktop>
        </BottomBanner>
      )}
    </Layout>
  )
}

export default PostTemplate

export const pageQuery = graphql`
  query($id: String!, $related: [Int]) {
    wordpressPost(id: { eq: $id }) {
      wordpress_id
      title
      content
      path
      date(formatString: "MMMM DD, YYYY")
      acf {
        bottom_banner
        content_field_01
        content_field_02
        content_field_03
        content_field_04
      }
      yoast_meta {
        yoast_wpseo_title
        yoast_wpseo_metadesc
      }
      featured_media {
        alt_text
        localFile {
          publicURL
          childImageSharp {
            fluid(maxWidth: 1224) {
              ...GatsbyImageSharpFluid_withWebp
            }
          }
        }
      }
    }
    allWordpressPost(
      filter: {
        wordpress_id: { in: $related }
        acf: { post_template: { ne: "service_post_carousel" } }
      }
      limit: 2
    ) {
      edges {
        node {
          slug
          title
          excerpt
          featured_media {
            alt_text
            localFile {
              childImageSharp {
                fluid(maxWidth: 350) {
                  ...GatsbyImageSharpFluid_withWebp
                }
              }
            }
          }
        }
      }
    }
    placeholder: file(relativePath: { eq: "placeholder.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 350) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
  }
`
